// types
import type {
  CourseMaterial,
  NavigationMenuItem
} from '@revolutionprep/types'

// stores
import { useTrialStore } from '@/store/trial'

/**
 * stores
 * ==================================================================
 */
const trialStore = useTrialStore()
const { isActiveTrial } = storeToRefs(trialStore)

const getCourseMaterialsRoute = (
  courseMaterials: CourseMaterial[] | undefined
) => {
  if (!courseMaterials?.length) {
    return null
  }
  return {
    id: 'navItemCourseMaterials',
    title: 'Materials',
    icon: 'custom:ArchiveBox',
    to: '/materials',
    external: false
  }
}

const menuItems =
  (courseMaterials?: CourseMaterial[]): NavigationMenuItem[] => {
    return [
      {
        id: 'navItemActivityFeed',
        title: 'Activity Feed',
        icon: 'custom:Home',
        to: '/',
        external: false
      },
      {
        id: 'navItemMyCalendar',
        title: 'My Calendar',
        icon: 'custom:CalendarDays',
        to: '/my-calendar',
        external: false
      },
      {
        id: 'navItemScheduleSessions',
        title: 'Schedule Sessions',
        icon: 'custom:CalendarCheck',
        to: '/schedule-sessions',
        chipText: isActiveTrial.value ? 'TRIAL' : '',
        external: false
      },
      getCourseMaterialsRoute(courseMaterials),
      {
        id: 'navItemVideos',
        title: 'Videos',
        icon: 'custom:VideoPlay',
        to: '/videos',
        external: false
      },
      {
        id: 'navItemExams',
        title: 'My Exams',
        icon: 'custom:Paper',
        to: '/exams',
        external: false
      }
    ].filter(menuItem => menuItem) as NavigationMenuItem[]
  }

export default menuItems
